
.Profile-icon path {
    fill: white;
}
.Profile-icon:hover path {
    fill: var(--themeColour);
}

.Profile-icon:hover circle {
    fill: white;
}
