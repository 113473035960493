
.ComparisonArrow {
}


.ComparisonArrow path {
    -webkit-transition: transform 500ms, opacity 500ms;
    -moz-transition: transform 500ms, opacity 500ms;
    -o-transition: transform 500ms, opacity 500ms;
    transition: transform 500ms, opacity 500ms;
    transform-origin: 5px 4px;
    opacity: 1;
}

.ComparisonArrow-down path {
    fill: var(--red)
}

.ComparisonArrow-up path {
    fill: var(--green);
    transform: rotate(180deg);
}

.ComparisonArrow-same path {
    fill: var(--grey2);
    transform: rotate(90deg);

}

.ComparisonArrow-null path {
    fill: var(--grey2);
    opacity: 0;
}