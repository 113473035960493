.FilterMenu {
    width: 220px;
    min-height: 100%;
    background: var(--white);
    padding: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.FilterMenu::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.FilterMenu {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
