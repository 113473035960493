
.Tab {
    background: var(--themeColour);
    border: 1px solid var(--pink);
    border-radius: 20px 20px 0 0;
    height: 49px;
    padding: 0 30px 0 30px;
    cursor: pointer;
    position: relative;
}

.Tab:hover, .Tab-selected {
    background: var(--pink);
}

.Tab h4 {
    color: var(--pink);
}
.Tab:hover h4, .Tab-selected h4 {
    color: var(--black);
}