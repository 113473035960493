.UnitComparison {
    padding: 20px;
    height: calc(100vh - 70px);
    width: calc(100% - 40px);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.UnitComparison::-webkit-scrollbar {
    display: none;
}
