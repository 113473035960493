
.ButtonSmall {
    background: none;
    cursor: pointer;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
    border: 1px solid var(--grey2);
}
.ButtonSmall:hover {
    background: var(--grey2);
}

.ButtonSmall .MuiTypography-root {
    color: var(--grey2);
}
.ButtonSmall:hover .MuiTypography-root {
    color: var(--white);
}

.ButtonSmall-filled {
    background: var(--grey2);
}

.ButtonSmall-filled .MuiTypography-root {
    color: var(--white);
}
