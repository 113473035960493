
.Note {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    background: var(--grey4);
    padding: 10px;
    padding-right: 40px;
    border-radius: 10px;
    /*width: calc(100% - 50px);*/
}
.Note p {
    white-space: pre-line;
}

.Note-editMode {
    padding-right: 10px;
}

.Note-menu {
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    visibility: hidden;
    background: var(--white);
    border-radius: 10px;
    padding: 8px;
}
.Note:hover .Note-menu {
    visibility: visible;
}

.Note-menu .MuiSvgIcon-root path {
    fill: var(--grey2);
    cursor: pointer;
}
.Note-menu .MuiSvgIcon-root:hover path {
    fill: var(--grey1);
}

.Note-info {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.Note-info p {
    /*margin: 4px;*/

}
.Note-info p:last-child {
    padding-left: 8px;
    border-left: 1px solid var(--grey5);
}

.Note-highPriority {
    background: var(--pink);
}

.Note-highPriorityIcon {
    position: absolute;
    top: 10px;
    right: 10px;
}
.Note-highPriorityIcon path {
    fill: var(--red);
}


.Note-editor {
    position: relative;
    height: max-content;
}

.Note-editor-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}