
.NotesEditor {
    position: relative;
    height: max-content;
}

.NotesEditor button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.NotesEditor-controls {
    position: absolute;
    bottom: 8px;
    right: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.NotesEditor-controls .MuiSvgIcon-root path {
    fill: var(--grey2);
}
.NotesEditor-controls:hover .MuiSvgIcon-root path {
    fill: var(--grey1);
}
.NotesEditor button:disabled .MuiSvgIcon-root path  {
    fill: var(--grey4);
    cursor: unset;
}