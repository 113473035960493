.RemoveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: none;
    height: 100%;
    width: 28px;
    background: none;
}
/*.RemoveButton:hover {*/
/*    background: #00000008;*/
/*}*/
.RemoveButton svg path {
    fill: var(--grey2);
}
.RemoveButton:hover svg path {
    fill: var(--grey1);
}