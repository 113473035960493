
.ScreenshotButton {
    position: absolute;
    top: 360px;
    right: 20px;
    background: var(--white);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.ScreenshotButton svg path {
    fill: var(--red);
}

.ScreenshotButton:hover {
    background: var(--red);
    border-radius: 20px;
    height: 40px;
    width: 40px;
}
.ScreenshotButton:hover svg path {
    fill: var(--white);
}
