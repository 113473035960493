
.FloorSelect-button {
  width: 40px;
  height: 39px;
  border: none;
  cursor: pointer;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.FloorSelect-levelFive {
  border-radius: 20px 20px 0 0;
}

.FloorSelect-upperFloor {
  /*border-radius: 20px 20px 0 0;*/
}

.FloorSelect-lowerFloor {
  border-radius: 0 0 20px 20px;
}
.FloorSelect-button path {
  fill: var(--red);
}

.FloorSelect-button:hover {
  background: var(--red);
}
.FloorSelect-button:hover path {
  fill: var(--white);
}

.FloorSelect .FloorSelect-selected:hover {
  background: var(--white);
  cursor: default;
}


.FloorSelect .FloorSelect-selected path {
  fill: var(--grey2);
}
