:root {
  --white: #FFFFFF;
  --pink: #F4E8E2;
  --black: #001B24;
  --red: #D11518;
  --grey1: #333333;
  --grey2: #aaaaaa;
  --grey3: #f5f5f5;
  --grey4: #ececec;
  --grey5: #cccccc;

  --green: #008864;
  --yellow: #FFDC44;
  --red2: #E94A2E;
  --red3: #D11518;

  --blue: #808d91;
  --blue2: #2D4295;

  --mensFashion: var(--blue);
  --mensFashion2: #818EBF;
  --leisure: var(--green);
  --jewellery: #668E99;
  --jewellery2: #004355;
  --mobilePhones: #7f9198;
  --footwear: var(--red2);
  --foodAndBeverage: #8BCAAE;
  --ladiesFashion: #E4B9D8;
  --healthAndBeauty: var(--yellow);
  --mixedFashion: #6D7E89;
  --mSU: #D1D5D9;
  --specialist: #E9EDF8;
  --null: var(--grey2);

  /* colour of the banner
     note: this is changed via JavaScript when the user clicks on internal/external */
  --themeColour: var(--black);

  --font-family: Lexend, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  --shadow: drop-shadow(0px 20px 10px #00000024);
}


body {
  background: var(--pink);

  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
