
.ZoomControls {
    position: absolute;
    top: 220px;
    right: 20px;
}

.ZoomControls button {
    background: var(--white);
    width: 40px;
    height: 39px;
    border: none;
    cursor: pointer;
}

.ZoomControls button path {
    fill: var(--red);
}
.ZoomControls button:hover {
    background: var(--red);
}
.ZoomControls button:hover path {
    fill: var(--white)
}

.ZoomControls-zoomIn {
    border-radius: 20px 20px 0 0;
}

.ZoomControls-zoomOut {
    border-radius: 0 0 20px 20px;
}