
.Search-icon {
    background: var(--white);
    border-radius: 20px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Search-icon svg {
    fill: var(--red);
}

.Search-icon:hover {
    background: var(--red);
    border-radius: 20px;
    height: 40px;
    width: 40px;
}
.Search-icon:hover svg {
    fill: var(--white);
}
