
.InternalExternal-icon path {
    fill: white;
}

.InternalExternal-button {
    height: 38px;
    width: 48px;
    border: 1px solid white;
    cursor: pointer;
}
.InternalExternal-button:hover {
    background: white;
}
.InternalExternal-button:hover .InternalExternal-icon path {
    fill: var(--themeColour);
}

.InternalExternal-button-selected {
    background: white;
}
.InternalExternal-button-selected .InternalExternal-icon path {
    fill: var(--themeColour);
}

.InternalExternal-button-internal {
    border-radius: 0 20px 20px 0;
    margin-left: -1px;
}

.InternalExternal-button-external {
    border-radius: 20px 0 0 20px;
}

