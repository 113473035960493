
.Button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--red);
    border: none;
    cursor: pointer;
    padding: 4px 20px 4px 20px;
    border-radius: 20px;
}

.Button .MuiTypography-root {
    color: var(--white);
}

.Button:hover {
    background: var(--black);
}

.Button-outline {
    background: none;
    border: 2px solid var(--red);
}
.Button-outline:hover {
    background: var(--red);
}
.Button-outline .MuiTypography-root {
    color: var(--red);
}
.Button-outline:hover .MuiTypography-root {
    color: var(--white);
}

.Button-disabled {
    cursor: unset;
    opacity: 0.5;
    pointer-events: none;
    background: var(--black);
    border-color: var(--black);
}