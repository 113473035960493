
.Table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Table::-webkit-scrollbar {
  display: none;
}


.Table-row-selected {
  background: var(--grey4) !important;
  padding-top: 12px;
  padding-bottom: 12px;
}

.Table-row .MuiTableCell-root {
  -webkit-transition: color 500ms, font-size 500ms, font-weight 500ms;
  -moz-transition: color 500ms, font-size 500ms, font-weight 500ms;
  -o-transition: color 500ms, font-size 500ms, font-weight 500ms;
  transition: color 500ms, font-size 500ms, font-weight 500ms;
}

.Table-row-selected .MuiTableCell-root {
  color: var(--blue2);
  font-weight: bold;
  font-size: 16px;
}